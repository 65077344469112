import React from 'react';
import styled from 'styled-components/macro';
import { moveInLeft, fadeIn } from './keyFrames';
import { below } from '../../styles/media';

const BannerContainer = styled.div`
  /* position: absolute;
  top: 25%;
  left: 2%; */
  color: white;
  text-align: left;
  border-radius: 10px;
  padding: 2rem;
  margin-left:2%;
  ${below.phone`
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
  `}

  ${below.tabPort`
    padding: 1rem;
  `}
`;

const Title = styled.span`
  display: block;
  font-size: 6rem;
  font-weight: 300;
  text-transform: uppercase;
  padding: 0 1rem;
  letter-spacing: 6px;

  ${below.phone`
    font-size: 4rem;
    margin-bottom: 1rem;
    letter-spacing: 4px;
  `}
`;

const TitleOne = styled(Title)`
  animation-name: ${moveInLeft};
  animation-duration: 2s;
  animation-timing-function: ease-out;

  ${below.tabPort`
    font-size: 4rem;
    letter-spacing: 4px;
  `}
`;

const TitleTwo = styled(Title)`
  animation-name: ${moveInLeft};
  animation-duration: 2s;
  animation-timing-function: ease-out;

  ${below.tabPort`
    font-size: 4rem;
    letter-spacing: 4px;
  `}
`;

const TitleThree = styled(Title)`
  animation: ${fadeIn} 4s;
  animation-fill-mode: forwards;
  font-weight: 900;

  ${below.tabPort`
    font-size: 4rem;
    letter-spacing: 4px;
  `}
`;

const SubTitle = styled.span`
  display: block;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  text-transform: none;
  font-size: 4rem;
  font-weight: 300;
  font-style: italic;
  letter-spacing: 2px;

  animation: ${moveInLeft} 2s ease-out;

  ${below.phone`
    font-size: 2.5rem;
    margin-bottom: 1rem;
  `}

  ${below.tabPort`
    font-size: 3rem;
    letter-spacing: 4px;
  `}
`;

const Info = styled.p`
  font-size: 2rem;
  text-transform: uppercase;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 3rem;
  letter-spacing: 4px;

  ${below.phone`
    margin: 0;
  `}
`;

const Banner = ({ title1, title2, title3, subTitle, info, children }) => {

  return (
    <BannerContainer>
      <TitleOne>{title1}</TitleOne>
      <TitleTwo>{title2}</TitleTwo>
      <SubTitle>{subTitle}</SubTitle>
      <TitleThree>{title3}</TitleThree>
      <Info>{info}</Info>
      {children}
    </BannerContainer>
  );
};

export default Banner;
