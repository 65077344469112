import React from 'react';
import styled from 'styled-components';
import { IconContext } from 'react-icons';

import Features from '../../constants/feature-list';
import Title from '../Title/title';
import TitleAppearOnScroll from '../../animations/title-appear-onscroll'


const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: 3rem;

  margin: 2.8rem 0;
padding-top:0;
  .feature__header {
    grid-column: 1 / -1;

    h2 {
      margin: 0;
    }
  }
`;

const FeatureCards = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 1rem;

  h4 {
    margin: 0;
    color: ${({ theme }) => theme.colors.mediumRedViolet};
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  .react-icons {
    border: 1.2px solid ${({ theme }) => theme.colors.mediumRedViolet};
    border-radius: 100%;
    padding: 1rem;
    width: 4.5rem;
    height: 4.5rem;
    color: ${({ theme }) => theme.colors.mediumRedViolet};
  }
`;

const FeatureSection = ({ className }) => {
    
    const FeatureLists = Features.map((feature, i) => (
        <FeatureCards key={i}>
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <div className="feature-icon">{feature.icon}</div>
      </IconContext.Provider>
      <div className="feature-description">
        <h4>{feature.title}</h4>
        <p>{feature.description}</p>
      </div>
    </FeatureCards>
  ));

  return (
    <FeatureContainer className={className}>
          <div className="feature__header">
              <TitleAppearOnScroll>
                  <Title className="feature__title" title="Who is a Godly Woman of Substance?" />
              </TitleAppearOnScroll> 
              {/*<Title className="feature__title" title="Who is a Godly Woman of Substance?" />*/}

      </div>
      {FeatureLists}
    </FeatureContainer>
  );
};

export default FeatureSection;
