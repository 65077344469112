import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';


const TitleAppearOnScroll = ({ children }) => {
    const { ref, inView } = useInView({
        threshold: 0,
    });
    const motionVaribles = {
        hidden: { opacity: 0, },
        visible: {
            opacity: 1,
            transition: { delay: .2, duration: 2, type: "spring", stiffness: 20 },
        },
    }
    return (
        <motion.div
            ref={ref}
            variants={motionVaribles}
            initial='hidden'
            animate={inView ? `visible` : ''}
            exit={{ opacity: 0 }}
        >

            {children}
        </motion.div>
    )
}
export default TitleAppearOnScroll;
