import BackgroundImage from 'gatsby-background-image';
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React from 'react';
import styled from 'styled-components/macro';
import { below } from '../../styles/media';
import Banner from '../Banner/banner';


const ButtonContainer = styled.div`
  //margin-right: 12rem;
  ${below.tabLand`
    margin-right: 4rem;
  `}

  ${below.tabPort`
    margin: 0;
  `}

  ${below.phone`
    margin: 2rem 0;
    border-radius: 5px;
  `}
 `;
ButtonContainer.displayName = 'ButtonContainer';

const StyledHero = ({ img, className, home }) => {

  const image = getImage(img)
  const bgImage = convertToBgImage(image)
  const isBrowser = () => typeof window !== "undefined"

  return (
    <section>
      <BackgroundImage
        className={className}
        Tag="section"
        {...bgImage}
        preserveStackingContext
        home={home}>
        <Banner title1="Godly" title2="Woman" subTitle="of" title3="Substance" />
        {/* <ButtonContainer>
                    {isBrowser() ? < VideoButton /> : null}
                </ButtonContainer >                                  */}
      </BackgroundImage>
    </section>
  );
};
export default StyledHero;
