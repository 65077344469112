import { keyframes } from 'styled-components';

export const moveInLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-100px)
    }

    80% {
        transform: translateX(10px)
    }

    100% {
        opacity: 1;
        transform: translate(0)
    }
`;

export const moveInRight = keyframes`
    0% {
        opacity: 0;
        transform: translateX(100px)
    }

    80% {
        transform: translateX(-10px)
    }

    100% {
        opacity: 1;
        transform: translate(0)
    }
`;

export const fadeIn = keyframes`
    0% {
        visiblity: hidden;
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
    }
`;

export const moveUpBottom = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100px)
    }

    100% {
        opacity: 1;
        transform: translate(0)
    }
`;
