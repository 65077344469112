import React from 'react';
import loadable from '@loadable/component';

import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';
import { graphql } from 'gatsby';
import StyledHero from '../components/Hero/StyledHero';
//import Layout from '../components/Layout';
//import Head from '../components/Head';
//import Products from '../components/Home/Products';
import FeatureSection from '../components/Home/FeatureSection';
//import FeatureEventSection from '../components/Home/FeatureEventSection';
//import EmpoweredSection from '../components/Home/Empowered';
//import EventSection from '../components/Home/EventSection';
//import TestimonialsMain from '../components/testimonials/testimonials-main'
//import Feature from '../components/Home/feature';
import styled from 'styled-components';
const TestimonialsMain = loadable(() => import('../components/testimonials/testimonials-main'));
const EmpoweredSection = loadable(() => import('../components/Home/Empowered'));
const Layout = loadable(() => import('../components/Layout'));
const Head = loadable(() => import('../components/Head'));

const PageContainer = styled.div`
  display: grid;

  /* TODO: Use this for the full home page including all sections wrapped in the <PageContainer> tag*/
  /* grid-template-rows: 80vh min-content 40vw repeat(3, min-content); */
  grid-template-rows: min-content;
  /* TODO: Look into making the 1fr at both ends a minimum size of 3rem? */
  /* grid-template-columns: minmax(3rem, 1fr) repeat(8, minmax(min-content, 14rem)) minmax(3rem, 1fr); */
  grid-template-columns: 1fr repeat(8, minmax(min-content, 14rem)) 1fr;

  .feature-section {
    /* background: #efefef; */
    grid-column: 2 / span 8;
  }
`;

const MyHome = ({ data }) => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Head title="Home" />
        <StyledHero home={true} img={data.file.childImageSharp.gatsbyImageData} />
        <EmpoweredSection />
        {/*<Feature/>*/}
        <PageContainer>
          <FeatureSection className="feature-section" />
        </PageContainer>
        {/*<ChapelSection /><Products />*/}
        {/*<FeatureEventSection />
                <EventSection />*/}
        <TestimonialsMain />
      </Layout>
    </ThemeProvider>
  );
};
export const query = graphql`
  query HomeHeroQuery {
    file(relativePath: { eq: "flori-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 70
          blurredOptions: { toFormat: WEBP }
          jpgOptions: { progressive: true }
          placeholder: TRACED_SVG
          tracedSVGOptions: { background: "#f1f1f1" }
          webpOptions: { quality: 90 }
        )
      }
    }
  }
`;
export default MyHome;
