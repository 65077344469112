import React from 'react';
import { AiFillCrown } from 'react-icons/ai';
import { GiBrain } from 'react-icons/gi';
import { FaChurch, FaMoneyBillAlt, FaUserFriends, FaSuitcase } from 'react-icons/fa';
import { IoIosFitness } from 'react-icons/io';
import { MdLightbulbOutline } from 'react-icons/md';

export default [
  {
    icon: <FaChurch />,
    title: `Spiritually Strong`,
    description: `Living out God’s purpose, walking day-by-day in the power, presence and principles of God’s word.`,
  },
  {
    icon: <FaUserFriends />,
    title: `Relationally Rich`,
    description: `She builds covenant and meaningful relationships, living in the culture of honour.`,
  },
  {
    icon: <AiFillCrown />,
    title: `A Royal Diadem`,
    description: `She is kingdom minded & purpose driven. An Esther 4:14 woman who focuses on her God centred dreams, vision, goals and plans.`,
  },
  {
    icon: <GiBrain />,
    title: `Mentally Sharp`,
    description: `Committed to learning and continuous self-development; always acquiring knowledge, walking in wisdom.`,
  },
  {
    icon: <MdLightbulbOutline />,
    title: `Practically Wise`,
    description: `Able to balance work, family life & daily challenges with ease, grace and excellence.`,
  },
  {
    icon: <FaSuitcase />,
    title: `Professionally Polished`,
    description: `Always on the cutting edge of the latest technology tools, being productive and globally relevant.`,
  },
  {
    icon: <IoIosFitness />,
    title: `Physically Fit`,
    description: `She walks in her covenant right of longevity, caring for her body, mind and soul as necessary in order to feel fabulous and fit for purpose.`,
  },
  {
    icon: <FaMoneyBillAlt />,
    title: `Financially Free`,
    description: `Economically empowered, business savvy, with a global mindset, employer of labour; kingdom financier; financially free and helping others succeed.`,
  },
];
